import { EventRule, EventRuleAction, EventRuleActionType, EventType } from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';

export type EventRuleState = Partial<EventRule> & {
  allFilled: boolean;
};

export class EventRuleStore {
  @observable
  public editableEventRule?: EventRule;

  @observable
  public state: EventRuleState = {
    name: '',
    coordinate: '',
    action: { type: EventRuleActionType.WEBHOOK, configuration: { url: '' } },
    allFilled: false,
  };

  @action
  public setState = (newState: Partial<EventRuleState>): void => {
    this.state = {
      ...this.state,
      ...newState,
    };

    this.state.allFilled = this.state.coordinate !== '' && this.state.name !== '' && this.state.action !== undefined;
  };

  @action
  public resetStore = (EventRule?: EventRule): void => {
    this.editableEventRule = EventRule;

    if (EventRule !== undefined) {
      const { name, coordinate, action, trigger } = EventRule;

      this.setState({
        name,
        coordinate,
        action,
        trigger,
      });
    } else {
      this.setState({
        name: '',
        coordinate: '',
        action: { type: EventRuleActionType.WEBHOOK, configuration: { url: '' } },
        trigger: { type: EventType.DEVICE_TRIGGER, properties: {} },
      });
    }
  };

  @action
  public setActionConfigValue(key: string, value: string): void {
    const { action } = this.state;

    if (action === undefined) {
      return;
    }

    this.setState({
      action: {
        ...action,
        configuration: {
          ...action.configuration,
          [key]: value,
        },
      },
    });
  }

  public getDefaultAction = (type: EventRuleActionType): EventRuleAction => {
    switch (type) {
      case EventRuleActionType.WEBHOOK:
        return {
          type,
          configuration: { url: '' },
        };
      case EventRuleActionType.MQTT:
        return {
          type,
          configuration: { topic: '' },
        };
      case EventRuleActionType.EKANBAN_TRIGGER:
        return {
          type,
          configuration: {
            compartmentSelectorName: '',
            compatmentSelectorCoordinate: '',
            inputJsonPath: '',
          },
        };
    }
  };
}
