import { FormStyles } from 'src/Common/Styles/FormStyles';
import { EventTriggerStore } from '../Stores/EventTriggerStore';
import { EventTrigger, EventType, Finder } from '@ekkogmbh/apisdk';
import { withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import { Button, Chip, Grid, IconButton, Popover, Stack, Tooltip, Typography } from '@mui/material';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { FinderPicker } from 'src/FinderManagement/FinderPicker';
import { enqueueSnackbar } from 'notistack';
import { Add } from '@mui/icons-material';

const styles = FormStyles;

interface EventTriggerFormStores {
  eventTriggerStore: EventTriggerStore;
}

interface EventTriggerFormState {
  expandedTriggerIndex?: number;
  popoverAnchor?: HTMLButtonElement | HTMLDivElement;
  pendingTrigger?: EventTrigger;
}

interface EventTriggerFormProps extends WithStyles<typeof styles> {
  coordinate: string;
  initialTrigger?: EventTrigger;
  excludeTypes?: EventType[];
}

@inject('eventTriggerStore')
@observer
class EventTriggerFormComponent extends React.Component<EventTriggerFormProps, EventTriggerFormState> {
  public state: EventTriggerFormState = {};

  get stores(): EventTriggerFormStores {
    return this.props as EventTriggerFormProps & EventTriggerFormStores;
  }

  private prettifyEventTypeString = (type: EventType): string => {
    let [context, name] = type.split('/');

    // when there is no '/'
    if (name === undefined) {
      context = 'internal';
      name = type as string;
    }

    const namePretty = name
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return `[${context}] ${namePretty}`;
  };

  private setPendingProperty(prop: string, value: string): void {
    const { pendingTrigger } = this.state;

    pendingTrigger!.properties[prop] = value;

    const { type, properties } = pendingTrigger!;

    this.setState({
      pendingTrigger: {
        type,
        properties,
      },
    });
  }

  public componentDidMount(): void {
    const { initialTrigger } = this.props;
    const { eventTriggerStore } = this.stores;

    eventTriggerStore.reset(initialTrigger);
  }

  public componentWillUnmount(): void {
    const { eventTriggerStore } = this.stores;

    eventTriggerStore.reset();
  }

  public typeSelect = (): JSX.Element => {
    const { excludeTypes } = this.props;
    const { eventTriggerStore } = this.stores;
    const { pendingTrigger } = this.state;

    const excludedValues = excludeTypes ? excludeTypes.map((ex: EventType) => ex.valueOf()) : [];
    const typeStrings = Object.values(EventType).filter((et: string) => !excludedValues.includes(et));
    const presetTypes = ['dashboard', 'event-rule-action'];

    const selectableValues = (typeStrings as string[]).concat(presetTypes);

    return (
      <div>
        <StyledSelectField
          native
          label={'Event Type'}
          value={pendingTrigger?.type ?? EventType.DEVICE_TRIGGER}
          onChange={(e) => {
            this.setState({ pendingTrigger: eventTriggerStore.getDefaultTrigger(e.target.value as EventType) });
          }}
        >
          {selectableValues.map((et: string) => (
            <option key={et} value={et}>
              {this.prettifyEventTypeString(et as EventType)}
            </option>
          ))}
        </StyledSelectField>
      </div>
    );
  };

  public triggerChip = (trigger: EventTrigger, index: number): JSX.Element => {
    const { eventTriggerStore } = this.stores;

    const tooltipContent = (
      <Stack direction={'column'}>
        <Typography>{'[properties]'}</Typography>
        {Object.keys(trigger.properties).map((key: string) => (
          <Stack key={`property-tooltip-${key}`} direction={'row'} spacing={2}>
            <Typography>{`${key}:`}</Typography>
            <Typography>{trigger.properties[key] as string}</Typography>
          </Stack>
        ))}
      </Stack>
    );

    return (
      <Chip
        label={
          <span style={{ position: 'relative' }}>
            <Tooltip title={tooltipContent}>
              <Typography
                variant={'overline'}
                color={'textPrimary'}
                align={'center'}
                display={'block'}
                style={{
                  lineHeight: 'normal',
                  fontWeight: 700,
                }}
              >
                {this.prettifyEventTypeString(trigger.type as EventType)}
              </Typography>
            </Tooltip>
          </span>
        }
        variant={'outlined'}
        style={{
          position: 'relative',
          margin: 8,
          height: 48,
          overflow: 'hidden',
        }}
        onClick={(e) =>
          this.setState({
            popoverAnchor: e.currentTarget!,
            expandedTriggerIndex: index,
            pendingTrigger: trigger,
          })
        }
        onDelete={() => {
          eventTriggerStore.removeTrigger(index);
        }}
      />
    );
  };

  public renderPropertyForm = (): JSX.Element => {
    const { eventTriggerStore } = this.stores;
    const { pendingTrigger } = this.state;

    // Improve form for events containing other entities? (query availables)
    if (pendingTrigger === undefined) {
      return <></>;
    }

    switch (pendingTrigger.type) {
      case EventType.DEVICE_GEOFENCE:
        return (
          <Stack direction={'column'}>
            <div>
              <StyledSelectField
                native
                label={'locationTrackingEventType'}
                value={pendingTrigger.properties.locationTrackingEventType}
                onChange={(e) => this.setPendingProperty('locationTrackingEventType', e.target.value as string)}
              >
                {['entry', 'exit', ''].map((ltet) => (
                  <option key={`${ltet}-option`} value={ltet}>
                    {ltet}
                  </option>
                ))}
              </StyledSelectField>
            </div>
            <StyledTextField
              label={'fenceName'}
              value={pendingTrigger.properties.fenceName}
              onChange={(e) => this.setPendingProperty('fenceName', e.target.value as string)}
            />
          </Stack>
        );
      case EventType.COMPARTMENT_FOUND:
        const { coordinate } = this.props;
        return (
          <Stack direction={'column'}>
            <div>
              <FinderPicker
                optional
                coordinate={coordinate ?? ''}
                selected={{
                  name: pendingTrigger.properties.finderName as string,
                  coordinate: pendingTrigger.properties.finderCoordinate as string,
                }}
                onChange={(finder: Finder | undefined) => {
                  if (finder) {
                    this.setPendingProperty('finderName', finder.name);
                    this.setPendingProperty('finderCoordinate', finder.coordinate);
                  } else {
                    this.setPendingProperty('finderName', '');
                    this.setPendingProperty('finderCoordinate', '');
                  }
                }}
                onError={() => {
                  enqueueSnackbar<'error'>('Could not load finders.');
                }}
              />
            </div>
            <StyledTextField
              label={'input'}
              value={pendingTrigger.properties.input}
              onChange={(e) => {
                this.setPendingProperty('input', e.target.value as string);
              }}
            />
          </Stack>
        );
      case EventType.GENERIC:
        const { context } = pendingTrigger.properties;

        // remove the key field for internal context keywords
        const hideKey = ['dashboard', 'event-rule-action'].indexOf(context as string) >= 0;
        return (
          <Stack direction={'column'}>
            <StyledTextField
              label={'context'}
              value={context}
              onChange={(e) => {
                this.setPendingProperty('context', e.target.value as string);
              }}
            />
            {!hideKey && (
              <StyledTextField
                label={'key'}
                value={pendingTrigger.properties.key}
                onChange={(e) => {
                  this.setPendingProperty('key', e.target.value as string);
                }}
              />
            )}
          </Stack>
        );
    }

    // Using a default instance to ensure that unset properties also have an input.
    const typeProperties = Object.keys(
      eventTriggerStore.getDefaultTrigger(pendingTrigger.type as EventType).properties,
    );

    // quick workaround for device trigger type disabling. @FIXME: come up with a cleaner solution
    const disabledProps = ['deviceTriggerType'];

    return (
      <Stack direction={'column'}>
        {typeProperties.map((propKey: string) => (
          <StyledTextField
            key={`prop-input-${propKey}`}
            label={propKey}
            value={pendingTrigger.properties[propKey] ?? ''}
            onChange={(e) => this.setPendingProperty(propKey, e.target.value as string)}
            disabled={disabledProps.indexOf(propKey) >= 0}
          />
        ))}
      </Stack>
    );
  };

  public render() {
    const { popoverAnchor } = this.state;
    const { eventTriggerStore } = this.stores;
    const { triggers } = eventTriggerStore.state;

    return (
      <>
        <Popover
          open={popoverAnchor !== undefined}
          anchorEl={popoverAnchor}
          onClose={() => {
            this.setState({ popoverAnchor: undefined, expandedTriggerIndex: undefined, pendingTrigger: undefined });
          }}
        >
          <Stack
            spacing={0}
            marginLeft={0}
            marginRight={2}
            marginTop={0}
            marginBottom={1}
            justifyContent={'center'}
            alignItems={'stretch'}
          >
            {this.typeSelect()}
            {this.renderPropertyForm()}
            <Button
              onClick={() => {
                const { expandedTriggerIndex, pendingTrigger } = this.state;

                if (pendingTrigger !== undefined) {
                  if (expandedTriggerIndex !== undefined) {
                    eventTriggerStore.setTrigger(expandedTriggerIndex, pendingTrigger!);
                  } else {
                    eventTriggerStore.addTrigger(pendingTrigger);
                  }
                }

                this.setState({ popoverAnchor: undefined, expandedTriggerIndex: undefined, pendingTrigger: undefined });
              }}
            >
              {'confirm'}
            </Button>
          </Stack>
        </Popover>
        <Stack direction={'column'}>
          <Grid container spacing={1} alignItems={'center'}>
            {triggers.map((t: EventTrigger, idx: number) => (
              <Grid item key={`${idx}-trigger-form-gitem`}>
                {this.triggerChip(t, idx)}
              </Grid>
            ))}
            <Grid item>
              <IconButton
                onClick={(e) => {
                  this.setState({
                    popoverAnchor: e.currentTarget,
                    expandedTriggerIndex: undefined,
                    pendingTrigger: eventTriggerStore.getDefaultTrigger(EventType.DEVICE_TRIGGER),
                  });
                }}
              >
                <Add />
              </IconButton>
            </Grid>
          </Grid>
        </Stack>
      </>
    );
  }
}

const StyleWrapped = withStyles(styles)(EventTriggerFormComponent);

export const EventTriggerForm = StyleWrapped;
